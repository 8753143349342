<template>
  <div>
    <div class="row mt-4 mt-md-0">
      <div class="col-md-6 d-flex align-items-center">
        <h3 class="color-text f-600">Inscrições</h3>
      </div>
      <div class="col-md-6 d-md-flex justify-content-end">
        <div class="position-relative as-icon">
          <InputText
            id="search"
            class="form-input"
            placeholder="Pesquisar..."
            type="text"
            required
            v-model="filters['global']"
          />
          <feather class="color-text is-18" type="search"></feather>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12" v-if="loading">
        <content-placeholders rounded>
          <content-placeholders-img />
          <content-placeholders-text :lines="4" />
        </content-placeholders>
      </div>
 
      <div class="col-12" v-if="!loading">
        <DataTable
          :paginator="true"
          :rows="10"
          dataKey="id"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          class="p-datatable-customers"
          :rowsPerPageOptions="[10, 20, 50]"
          currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}"
          selectionMode="single"
          @row-select="getPedido"
          :value="pedidos"
        >
          <Column field="cliente" header="CLiente" sortable></Column>
          <Column field="data" header="Data" sortable>
            <template #body="slotProps">
              {{ formatDate(slotProps.data.data) }}
            </template>
          </Column>
          <Column field="nome" header="Treinamento"></Column>
          <Column
            field="inscritos"
            header="Total inscritos"
            centerColumn
            sortable
          >
            <template #body="slotProps">
              <span class="badge badge-success">{{
                slotProps.data.inscritos
              }}</span>
            </template>
          </Column>
          <Column field="status" header="Status" sortable>
            <template #body="slotProps">
              <span
                class="badge badge-warning"
                v-if="slotProps.data.status == 'pendente'"
                >{{ slotProps.data.status }}</span
              >
              <span
                class="badge badge-primary"
                v-if="slotProps.data.status == 'contrato'"
                >{{ slotProps.data.status }}</span
              >
              <span
                class="badge badge-success"
                v-if="slotProps.data.status == 'pago'"
                >{{ slotProps.data.status }}</span
              >
              <span
                class="badge badge-danger"
                v-if="slotProps.data.status == 'cancelado'"
                >{{ slotProps.data.status }}</span
              >
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <Dialog
      :header="'Pedido - ' + pedidoSingle.id"
      :visible.sync="displayModal"
      :style="{ width: '70vw' }"
      :modal="true"
    >
      <div class="row p-md-5">
        <div
          v-if="pedidoSingle"
          class="col-12 d-md-flex justify-content-between align-items-center"
        >
          <div>
            <small
              class="category fs-12 f-600 color-secondary text-uppercase"
              v-if="pedidoSingle"
              >{{ pedidoSingle.produto.categoria[0].name }}</small
            >

            <h5 class="title color-primary fs-18 py-3 m-0 f-600 text-uppercase">
              {{ pedidoSingle.produto.treinamento }}
            </h5>
            <small class="category fs-14 f-600 color-text">
              {{ pedidoSingle.produto.cidadeEstado }}</small
            >
            <p class="date fs-16 color-primary mt-2 d-flex align-items-center">
              <feather
                class="color-secondary is-14 mr-1"
                type="calendar"
              ></feather>
              {{ pedidoSingle.produto.dataInicio }} -
              {{ pedidoSingle.produto.dataFim }}
            </p>
          </div>
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <span
              class="payment-status d-none d-md-block bg-warning"
              :class="
                pedidoSingle.status == 'pendente' ? 'bg-warning' : 'bg-primary'
              "
              v-if="pedidoSingle"
              >{{ pedidoSingle.status }}</span
            >
            <small
              class="fs-12 fw-500 pt-2 d-none d-md-block"
              v-if="pedidoSingle.pagamento"
              >{{ pedidoSingle.pagamento[0].authorizationCode }}</small
            >
          </div>
        </div>
        <div class="col-12">
          <hr class="color-tertiary bg-tertiary" />
        </div>
        <div class="col-12">
          <div class="row" v-if="loading">
            <div class="col">
              <content-placeholders>
                <content-placeholders-heading :img="true" />
                <content-placeholders-text :lines="8" />
                <content-placeholders-heading :img="true" />
              </content-placeholders>
            </div>
          </div>
          <div class="row mt-3">
            <div
              class="col-md-9"
              v-for="(participante, index) in pedidoSingle.participantes"
              :key="participante.id"
            >
              <h5 class="color-text text-uppercase fs-14 f-600 mb-3">
                <feather
                  class="color-tertiary is-14 mr-1"
                  type="user"
                ></feather>
                <span> Dados do participante {{ index + 1 }}</span>
              </h5>
              <form action="">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="username" class="fs-12 color-text"
                      >Nome completo</label
                    >
                    <InputText
                      class="form-input"
                      type="text"
                      required
                      v-model="participante.nome"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >Nome de exibição do Certificado</label
                    >
                    <InputText
                      class="form-input"
                      type="text"
                      v-model="participante.nome_cert"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text">CPF</label>
                    <InputText
                      v-model="participante.cpf"
                      class="form-input"
                      type="text"
                      v-mask="['###.###.###-##']"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >Data de nascimento</label
                    >
                    <input
                      id="data"
                      class="form-input"
                      type="date"
                      v-model="participante.nascimento"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >Pronome que combina mais com você</label
                    >
                    <InputText
                      v-model="participante.pronome"
                      class="form-input"
                      type="text"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >Telefone de contato</label
                    >
                    <InputText
                      v-model="participante.telefone"
                      class="form-input"
                      type="text"
                      v-mask="['(##) # ####-####']"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >E-mail de contato</label
                    >
                    <InputText
                      id="email"
                      v-model="participante.email"
                      class="form-input"
                      type="text"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text"
                      >Empresa</label
                    >
                    <InputText
                      class="form-input"
                      v-model="participante.empresa"
                      type="text"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="username" class="fs-12 color-text">Cargo</label>
                    <InputText
                      v-model="participante.cargo"
                      class="form-input"
                      type="text"
                      disabled
                    />
                  </div>
                </div>
              </form>
              <hr class="py-3" />
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <h5 class="color-text text-uppercase fs-16 mb-4 f-600">
                  <feather
                    class="color-tertiary is-16 is-1 mr-1"
                    type="file"
                  ></feather>
                  Documentos para Download
                </h5>
              </div>
              <div
                class="col-md-6 mt-4 mt-md-0"
                v-for="arquivo in pedidoSingle.arquivos"
                :key="arquivo.id"
              >
                <div
                  class="card border-0 shadow-sm documentos position-relative"
                >
                  <a
                    :href="arquivo.arquivo"
                    target="_blank"
                    class="link-download"
                    download
                  ></a>
                  <div
                    class="card-body d-flex justify-content-center flex-column align-items-center"
                  >
                    <feather
                      class="is-24 mr-2 mb-3"
                      type="download-cloud"
                    ></feather>
                    <p class="fs-18 small text-uppercase">
                      {{ arquivo.nome }}
                    </p>
                    <small class="text-center">Clique para baixar</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <file-pond
        name="test"
        ref="pond"
        class="uploader"
        label-idle="Arraste seus documentos ou clique para carregar"
        accepted-file-types="image/jpeg, image/png, application/pdf"
        allow-multiple
        data-max-file-size="15MB"
        data-max-files="6"
        v-bind:files="files"
      />
      <template #footer>
        <button
          type="button"
          class="btn btn-success px-4 f-600"
          @click="sendFiles"
          :disabled="loading"
        >
          <div
            class="spinner-grow text-light"
            role="status"
            v-if="loading"
          ></div>
          <span class="fs-14 text-uppercase" v-if="!loading"
            >Enviar Arquivos</span
          >
        </button>
        <!--   <button
          type="submit"
          class="btn btn-success"
          style="width: 250px"
          :disabled="loading"
        >
          <div
            class="spinner-grow text-light"
            role="status"
            v-if="loading"
          ></div>
          <span class="fs-14 text-uppercase" v-if="!loading">Cadastrar</span>
        </button> -->
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: "Inscricoes",
  components: {
    DataTable,
    Column,
    Dialog,
    FilePond,
  },
  data() {
    return {
      filters: {},
      files: [],
      pedidos: [],
      pedidoSingle: "",
      loading: false,
      displayModal: false,
    };
  },
  methods: {
    openModal() {
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
    previewImage() {
      this.files = this.$refs.pond.getFiles();
    },
    handleFilePondInit() {
      console.log("FilePond has initialized");
    },
    sendFiles() {
      this.files = this.$refs.pond.getFiles();
      this.loading = true;
      var data = new FormData();
      console.log(this.files)
      this.files.forEach((element) => {
        data.append("file", element.file, element.file.name);
      });
      data.append("pedido_id", this.pedidoSingle.id);
      this.$http
        .post("add-anexo", data)
        .then(() => {
          this.$refs.pond.removeFiles();
          this.loading = false;
          this.$toast.add({
            severity: "success",
            summary: "Arquivo",
            detail: "Arquivo salvo!",
            life: 3000,
          });
        })
        .then(() => {
          this.getPedido(this.pedidoSingle.id)
                    this.$toast.add({
            severity: "error",
            summary: "Arquivo",
            detail: "Falha ao salvar o arquivo, tente novamente.",
            life: 3000,
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatDate(valor) {
      let date = new Date(valor);
      var formatter = new Intl.DateTimeFormat("pt-BR");
      return formatter.format(date);
    },
    getPedido(event) {
      this.loading = true;
      this.$http
        .get("/pedido?pedido_id=" + event.data.id)
        .then((response) => {
          this.loading = false;
          this.pedidoSingle = response.data;
          this.openModal();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getPedidos() {
      this.loading = true;
      this.$http.get("/pedidos").then((response) => {
        this.loading = false;
        this.pedidos = response.data.pedidos;
      });
    },
  },
  created() {
    this.getPedidos();
  },
};
</script>

<style lang="scss">
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 64em) {
  /deep/ .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
.as-icon {
  width: calc(100% - 13vw);
  i {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}

.payment-status {
  color: white;
  padding: 2rem 2.7rem;
  border-radius: 5px;
}

.documentos {
  background: $secondary;
  color: white;
  cursor: pointer;
}
.link-download {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
</style>